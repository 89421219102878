@import url('https://fonts.googleapis.com/css2?family=Anek+Odia:wght@100;300&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@500&display=swap');
.MainBody{
 // border: 2px solid black;
     //min-height: 100vh;
     //min-height: 100vh;
     //margin-top: 60px;
   
 
 }

 .Impressum{
     height: 100vh;
     width: 100%;
     background-color: whitesmoke;

     p{
         position: absolute;
        font-family: 'Anek Odia', sans-serif;
        left:50%;
        top:50%;
        transform: translate(-50%,-120%);
        font-size: 6vw;
     }

     p2{
        position: absolute;
       font-family: 'Anek Odia', sans-serif;
       left:50%;
        top:50%;
        transform: translate(-50%, 40%);
       font-size: 2.5vw;
       font-weight: bolder;
       

       a{
           text-decoration: none;
           color:#2ba6cb
       }
      
       a:hover{
        color:#176780
    }
    }

    p3{
        position: absolute;
       font-family: 'Anek Odia', sans-serif;
       left:50%;
        top:90%;
        transform: translate(-50%, 0%);
       font-size: 1.2vw;
       font-weight: bolder; 

       a{
        text-decoration: none;
        color:#2ba6cb
    }
   
    a:hover{
     color:#176780
 }
    }


    p4{
        position: absolute;
       font-family: 'Anek Odia', sans-serif;
       left:50%;
        top:62%;
        transform: translate(-50%, 0%);
       font-size: 1.2vw;
       font-weight: bolder; 

       a{
        text-decoration: none;
        color:#2ba6cb
    }
   
    a:hover{
     color:#176780
 }
    }
 }
 
 .MainVideoList{
    margin: 0;
    text-align: end;
    width: 100%;
    background: white;
    
   
 }
 
 
 .divforvideoplayers{
    //background-color: black;
    //border: 2px solid blue;
   
    
 }
/*
.Iframe{
    width: 295px;
    height: 166px;
    cursor: pointer;
    border-radius: 10px;
}
*/



.YLogo_org{
    width: 100%;
}

.YLogo_org_name{
    margin-left: 5px;
    font-size: 21px;
    font-weight: bold;
    
}

.Foo{
 
    display: none;
}

.Button1{
    display: flex;
    padding: 0px 10px 0px 10px;
    align-items: center;
    background: #f2f2f2;
    height: 30px;
    font-weight: 600;
    border-radius: 10px;
    text-align: center;
    font-size: 14px;
    -webkit-user-select: none;
    user-select: none;
    margin: 0px 5px 0px 20px;

}
.Button1:hover{
 cursor: pointer;
 background-color: #e6e6e6;
}

.Buttons{
   
    width: 100%;
   
    display: flex;
    height: 56px;
    border-bottom: 1px solid #e5e5e5;
    position: sticky;
    top: 61px;
    white-space: nowrap;
    overflow: auto;
    background-color: white;
    opacity: 0.95;
    align-items: center;
   

}

#alle{
    background-color: black;
    color: white;
}

.Buttons::-webkit-scrollbar{
 width: 1px; 
 height: 10px;
}
.Buttons::-webkit-scrollbar-track{
 background-color: transparent;
}
.Buttons::-webkit-scrollbar-thumb{
 background-color: #e5e5e5;
 border-radius: 50px;
}



@media screen and (min-width: 1200px) and (max-width: 1600px) {

    .MainVideoList{
      
        width: 85%;
        
       
     }

}

@media  (max-width: 576px) {
    

    .MainVideoList{
        text-align: center;
    }

    .Buttons{
        display: none;
       }

    .Foo{
        background-color: white;
         width: 100%;
         height: 45px;
         bottom: 0px;
         position: fixed;
         bottom: 0;
         display: block;
     }

    
    }