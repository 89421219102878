@import url('https://fonts.googleapis.com/css2?family=Anek+Odia:wght@100;300&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@500&display=swap');


@media  (min-width: 2048px) {
    .VideoPlayer{
        width: 330px !important;
     }
     
}


.MainBody{
    display: flex;
    height: calc(100vh - 60px);
    
 }

 .Impressum{
     height: 100vh;
     width: 100%;
     background-color: whitesmoke;
     display: flex;
     justify-content: center;
     align-items: center;
     
    


 }


 .p{
   font-family: 'Anek Odia', sans-serif;
   cursor: pointer;
   font-size: 5vw;
   font-weight: bold;
}


.textp2{
    
   font-family: 'Anek Odia', sans-serif;
  
  // font-size: 1.5vw;
   font-size: 2vw;
   font-weight: bolder;
   text-align: center;
   

   a{
       text-decoration: none;
       color:#2ba6cb
   }
  
   a:hover{
    color:#176780
}
}


.textp3{
    
   font-family: 'Anek Odia', sans-serif;

   font-size: 2vw;
   font-weight: bolder; 
   text-align: center;
   a{
    text-decoration: none;
    color:#2ba6cb
}

a:hover{
 color:#176780
}
}


.textp4{
 
   font-family: 'Anek Odia', sans-serif;
   font-size: 2vw;
   font-weight: bolder; 
   text-align: center;

   a{
    text-decoration: none;
    color:#2ba6cb
}

a:hover{
 color:#176780
}
}


.textp5{
   
   font-family: 'Anek Odia', sans-serif;
  
   font-size: 1vw;
   font-weight: bolder; 
   text-align: center;
    margin-top: 50px;
   a{
    text-decoration: none;
    color:#2ba6cb
}

a:hover{
 color:#176780
}
}


#prenon{
    font-size: 1vw;
}

#midnon{
    font-size: 1vw;
}

#afternon{
    font-size: 1vw;
}


.FootText{
    position: absolute;
    font-family: "Anek Odia", sans-serif;
  
    bottom: 0;
    
    font-size: 1vw;
    font-weight: bolder;

    a{
     text-decoration: none;
     color:#2ba6cb
 }
}

 .ImpressumLinks{
    width: 75%;
    height: 450px;
   
    display: flex;
justify-content: center;
flex-direction: column;
align-items: center;


}



.A{
    position: absolute;
width: 100%;
height: 40vh;
background: blue;
top: 0;

clip-path: polygon(0px 0px, 110% 0px, 100% 560px, 100% 99.47%, 0px 47.20%);

display: flex;
justify-content: center;
align-items: center;
color: white;
}
 
 .VideoList{

   margin: 0;
   display: flex;
   flex-direction: column;
    width: 100%;
    
 
      
   
    
 }

 .videos_container{
    text-align: center;
 }
 
 .VideoPlayer{
 
    //width: 295px;
    width: 296px;
   display: inline-block;
     height: 285px;
     
     margin: 15px;
     vertical-align: top;
     text-align: left;
     border-radius: 10px;
  
 }
 
 
 .divforvideoplayers{
    //background-color: black;
    //border: 2px solid blue;
   
    
 }

.Iframe{
    width: 100%;
    height: auto;
    cursor: pointer;
    border-radius: 10px;
}

.Circl{
    width: 40px;
    height: 40px;
    object-fit: cover;
	-moz-border-radius: 50px;
	-webkit-border-radius: 50px;
	border-radius: 50px;
    margin-left: 0;
    color: white;
   display: inline-block;
    margin-top: 2px;
}
.VideoName{
    font-family: 'Roboto', sans-serif;
    font-size: 14px;
    font-weight: 500;
    letter-spacing: 0.25px;
    display: inline-block;
    cursor: pointer;
}

.TitleContainer{
    width: auto;
    cursor: pointer;
    margin-left: 3%;

}

.TitleContainer div{
    overflow: hidden;
  text-overflow: ellipsis;
display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  line-height: 20px;
  max-height: 60px;
  cursor: pointer;
  margin-top:5px;

}

.ViewandChannel{
    font-family: Arial, Helvetica, sans-serif;
    font-size: 14px;
    cursor: pointer;
    letter-spacing: 0.25px;
    margin-bottom:5px;
    color: #595959;
}


.Foo{
 
    display: none;
}

.FooterButtons{
    display: flex;
    flex-direction: column;
    align-items: center;
   
}


.Dialog{
 
    width: 100%;
    height: 100vh;
    position: absolute;
    display: none;
    background: white;
    opacity: 1;
    z-index: 3;
    top: 0;
    align-items: center;
 
 
    #textandbuttonbox{
        width: 100%;
        height: auto;
        display: flex;
        justify-content: center;
        flex-direction: column;
        align-items: center;
    }
 
    

 
    #klicktext{
        font-size: 25px;
    }
 
    #weiterbtn{
        width: 120px;
        height: 40px;
        color: white;
        text-transform: uppercase;
        background-color: #2ba6cb;
        justify-content: center;
        display: flex;
        align-items: center;
        font-weight: bold;
        font-size: 14px;
        border-radius: 10px;
        margin-top: 80px;
        cursor: pointer;
    }
 
 
}


// Banner

.youtcheck-Banner-long{
    background:white;
    width: 100%;
    min-height: 100vh;
    position: absolute;
    top: 0;
    z-index: 2;
}


.BannerDetails{
    display: none;
}


.youtcheck-Banner-long-head{

    
    width: 100%;
    height: 65px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.youtcheck-Banner-long-head-logo{
height: 30px;


margin-left: 2vw;
display: flex;
align-items: center;
justify-content: center;

img{
    width: 80%;
    height: inherit;

}
}




.youtcheck-Banner-long-head-login{
    border: 1px solid #cecece;
    height: 30px;
    min-width: 72px;
    
    margin-right: 2vw;
    border-radius: 18px;
    color: #065fd4;
    font-weight: 700;
    font-size: 14px;
    display: flex;
    align-items: center;
    padding: 0 10px 0 0px;
    

    svg{
        
        width: 24px;
        height: 24px;
        padding: 0 10px;
    }

}


.youtcheck-Banner-long-text-block{
    
    
    padding: 1.5vw;
    display: flex;
    flex-direction: column;
    align-items: center;
    
    
    
}

.youtcheck-Banner-long-text-block-heading{
    
    font-weight: bold;
    color: black;
   
    text-align: center;
    font-size: 28px;

}




.youtcheck-Banner-long-text-block-text{
 
    max-width: 700px;
    width:100%;
   // border: 1px solid red;
    font-size: 0.9em;
    color: black;
    
}

.youtcheck-Banner-text{
 
    margin-top: 1vw;
    padding: 0 1vw 0 1vw;
}


.youtcheck-Banner-activate-history{
      border: 1px solid #cecece;
      border-radius: 18px;
      margin-top: 1vw;
      width: 100%;
      min-height: 300px;
      display: flex;
      flex-direction: column;
}

.youtcheck-Banner-activate-history-text-head{
    font-weight: bold;
    font-size: 18px;
    color: black;
    padding: 0.8vw 0 0 1.2vw;
}

.youtcheck-Banner-activate-history-text-text{
    padding-left: 1.2vw;
    margin-top: 0.6vw;
}

.youtcheck-Banner-activate-history-text-img{
    
    display: flex;
}


.youtcheck-Banner-activate-history-img{
   width: 50%;
    height: 140px;

    display: flex;
    align-items: center;
    justify-content: center;

    img{
        width: 55%;
        height: auto;
    }
}



.youtcheck-Banner-activate-history-text{
    height: fit-content;

    width: 50%;
    
}


.youtcheck-Banner-activate-history-btn{

    display: flex;
    justify-content: end;
    align-items: center;
    height: 60px;

    div{
        border: 1px solid #acacac;
        color: black;
        font-weight: bold;
        border-radius: 18px;
        max-width: 70px;
        width: 100%;
        margin-right: 1vw;
        display: flex;
        justify-content: center;
        align-items: center;
        height: 30px;
    }
}

.youtcheck-Banner-accordion{
    border-bottom: 1px solid #cecece;
}

.youtcheck-accordion-text{
    color: #606060;
    font-size: 14px;
}

.youtcheck-historybtn:hover{
    cursor: pointer;
    background-color: #eeeded !important;
}

.youtcheck-Banner-btns{
 
    color: white;
    font-weight: 600;
    display: flex;
    flex-wrap: wrap;
    justify-content: end;
    margin-top: 1vw;
    position: relative;
}

.youtcheck-Banner-btn{
  background: black;
  border-radius: 18px;
  max-width: 200px;
  width: fit-content;
  padding: 0 20px 0 20px;
  height: 38px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 15px;
  cursor: pointer;
}
.Bannerbtnaccept{
 border: 1px solid black;
 background: white;
 color: black;
}

.youtcheck-banner-switcher{
   
    display: flex;
    justify-content: center;
    margin: 40px 0 40px 0;
}

.btnBack{
    position: absolute;
    left: 0;
    border: 1px solid black;
    background: white;
    color: black;
}

.VideoPlayerDescr-Container{
    
    display: flex;
}

@media screen and (min-width: 1281px) and (max-width: 1800px) {

    .VideoList{
      
        width: 86%;
        
       
     }

     .VideoPlayer{
        width: 265px;
     }


}


@media  (max-width: 576px) {
    
  
    


    .Dialog{
 
        width: 100%;
        height: 100dvh;
        position: absolute;
        display: none;
        background: white;
        opacity: 1;
        z-index: 3;
        top: 0;
        align-items: center;

        #textandbuttonbox{
            width: 100%;
            height: auto;
            display: flex;
            justify-content: center;
            flex-direction: column;
            align-items: center;
        }


        #klicktext{
            font-size: 25px;
        }
        #weiterbtn{
            width: 120px;
        height: 40px;
        color: white;
        text-transform: uppercase;
        background-color: #2ba6cb;
        justify-content: center;
        display: flex;
        align-items: center;
        font-weight: bold;
        font-size: 14px;
        border-radius: 10px;
        margin-top: 80px;
        }
 
    
    }

    .VideoList{
        margin: 0;
        text-align: center;
        width: 100%;
       
    
         
      
       
    }

    .Foo{
        background-color: white;
         width: 100%;
         height: 45px;
         bottom: 0px;
         position: fixed;
         bottom: 0;
         display: block;
     }

     .IconsFoot{
        width: inherit;
        height: inherit;
        display: flex;
        justify-content: space-around;
        
        
     }


     .p{
        font-size: 6vw;
        
     }

     .textp2{
        font-size: 5vw;
     }
     .textp3{
        font-size: 5vw;
     }
     .textp4{
        font-size: 5vw;
     }
  
     .FootText{
        font-size: 2.0vw;
     }

     #prenon{
        font-size: 3vw;
    }

    #midnon{
        font-size: 3vw;
    }

    #afternon{
        font-size: 3vw;
    }


        //banner
        .youtcheck-Banner-btn{
            width: 80%;
            max-width: none;
            margin-top: 4vw;
        }
    
        .youtcheck-Banner-btns{
            justify-content: center;
        }
    
    .youtcheck-Banner-long-text-block-container{
        overflow: scroll;
        height: 54vh;
    }
       
    
    // head
    
    .youtcheck-Banner-long{
        background:white;
        width: 100%;
        min-height: 100vh;
        position: absolute;
        top: 0;
        z-index: 2;
    }
    
    .youtcheck-Banner-long-head{
    
        
        width: 100%;
        height: 65px;
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
    
    
    
    
    
    
    .youtcheck-Banner-long-head-login{
        border: 1px solid #cecece;
        height: 30px;
        min-width: 72px;
        
        margin-right: 2vw;
        border-radius: 18px;
        color: #065fd4;
        font-weight: 700;
        font-size: 14px;
        display: flex;
        align-items: center;
        padding: 0 10px 0 0px;
        
    
        svg{
            
            width: 24px;
            height: 24px;
            padding: 0 10px;
        }
    
    }
    
    // head
    
    
    
        .youtcheck-Banner-long-head-logo{
            height: 25px;
            
            
            margin-left: 2vw;
            display: flex;
            align-items: center;
            justify-content: center;
            
            img{
                width: auto;
                height: inherit;
                
            }
            }
            
            
            
            
            
            .youtcheck-Banner-long-text-block{
                
                
                padding: 1.5vw;
                display: flex;
                flex-direction: column;
                align-items: center;
                margin-top: 5vw;
                
                
            }
            
            .youtcheck-Banner-long-text-block-heading{
                
                font-weight: bold;
                color: black;
               
                text-align: center;
                font-size: 23px;
            
            }
    
    
            .youtcheck-Banner-activate-history{
               
                margin-top: 8vw;
                
          }
    
            .youtcheck-Banner-activate-history-text-head{
                font-weight: bold;
                font-size: 18px;
                color: black;
                padding: 0.8vw 0 0 1.2vw;
            }
    
    
    
    
            .youtcheck-Banner-activate-history-text-head{
                font-weight: bold;
                font-size: 18px;
                color: black;
                padding: 0.8vw 0 5vw 1.2vw;
            }
    
            .youtcheck-Banner-activate-history-text{
               
                width: 65%;
                
            }
    
    
            .youtcheck-Banner-activate-history-text-text{
                padding-left: 1.2vw;
                margin-top: 0.6vw;
                
            }
    
    
            .youtcheck-Banner-activate-history-img{
                width: 35%;
                 height: 140px;
                
                 display: flex;
                 align-items: center;
                 justify-content: center;
             
                 img{
                     width: 55%;
                     height: auto;
                 }
             }
    
             .youtcheck-Banner-activate-history-text-img {
               
                align-items: center;
            }
    
    
            .youtcheck-banner-switcher {
                flex-wrap: wrap;
            }
    
            .youtcheck-options-switch{
                margin-bottom: 5vw;
            }
    
    
            .btnBack{
             position: relative;
            }




    }