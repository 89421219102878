.Main{
  //  border: 2px solid black;
    min-height: 100vh;
    max-width: 1980px;
    width: 100%;
    display: flex;
    justify-content: center;
    

}



#bodyVideo{
  display: flex;
  justify-content: center;
}

.Coverlogo{
  background-color: transparent;
  width: 80px;
height: 40px;
position: absolute;
bottom: 0;
right: 0;
}

.OverlayCase{
  position: relative;

 
}

.Werbung{
    height: 495px;
    width: 100%;
    
}

#Comments{
  display: none;
}

.Adshell{
  position: relative;
  display: flex;
  height: 100%;
}



.ChannelPanel{

  display: flex;
  justify-content: space-between;
}

.ImgAndAbo{

  
  display: flex;
  align-items: center;
  flex-basis: 35%;
  
}

.Container-mobile{
display: flex;
align-items: center;
width: 245px;


}


.AboDiv{
 
  height: 41px;
  display: flex;
  position: relative;
  width: 120px;
 
  padding-left: 0px;
  min-width: 120px;
 
}


.AboBtn{
  height: 40px;
  background-color: #000000;
  width: 100%;
  border-radius: 30px;
  text-align: center;
  color: white;
  font-weight: bold;
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
 font-size: 14px;
 
}


.ButtonsPanel{
   
  position: relative;

  display: flex;
justify-content: end;
align-items: center;


}


.Channel-container{
  display: flex;
}



.Back{
  cursor: pointer;
  
  pointer-events: all;
  font-size: 1.5vw;
  font-weight: bold;
  border: 2px solid;
  width: 25%;
  margin: 0 auto;
  background: white;
  color: black;
  border-radius: 10px;
}



.AdPanel{
  position: absolute;
  width: 100%;
  height: 60px; //80px
  bottom: 0; //18%
  background: linear-gradient(transparent, #131313);
  opacity: 0;
  display: inline-block;
  cursor: pointer;
  transition: 0.3s all;
  border-radius: 10px;
}

.Unmute{
position: absolute;
bottom: 45%;
left: 30%;
width: 15%;
height: auto;
background-color: #ffffff;
color: black;
border-radius: 30px;
opacity: 0.8;
text-align: center;
animation: glow 1.5s infinite alternate both;
cursor: pointer;
display: none;
  p{
      opacity: 1;
     // color:black;
      font-weight: bold
  }

}

@keyframes glow{

  0%{background-color: #ffffff;color:black;}
  100%{background-color: #000000;color:white;}
}

.Videoplayer{
    //border:2px solid green;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
    height: auto; //807px
    margin-top: 2%;
    position: relative;

    
}

.Videoplayer:hover .AdPanel{
 
  opacity: 1;
 

//transition: 1s;
  
}


.Videoplayer:hover #playbtn{
 
  opacity: 1;
  border-radius: 15px;
background-color: black;
transition: 1s;
  
}

.Videoplayer:hover #pausebtn{
opacity: 1;
border-radius: 15px;
background-color: black;
transition: 1s;
  
}

#volumebtn{
  top:15%;
  width: 98%;
  margin: 0 auto;
  position: relative;
  color: white;
  height: 28px;
}


.ForwardWindow{
  //width: 60%;
  height: 495px;
  position: absolute;
  background-color: #333333;
  z-index: 1;
  border: 4px solid black;
//  top: 10%;
//  left:20%;
//  border-radius: 40px;
  display:none;
  text-align: center;

  p{
      font-size: 2.5vw;
      font-weight: bold;
  }
}

.ForwardWindowImg{
width: 30%;
height: auto;
margin-top: 80px;
}

.ForwardWindowText{
font-size: 2vw;
}

/*
.Iframe{
  width: 400px;
  height: 350px;
  border: 2px solid black;
  float:right;
}

.Content{
  width: 100%;
  height: auto;
}
*/

.PlayBtn{
border-radius: 5px;
width: 20px;
background-color: white;
margin-left: 10px;
}


#slider{
opacity: 0;
  width: 12%;
 transition: 1s all; 
 color: white;
 padding: 0;
}



.Videolist{
   // border: 2px solid red;
  //  min-height: 100vh;
  //  margin-left: 25px;
    //width: 402px;
  //  width:27%;
     //width: 370px;
  //  display: inline-block;
    position: relative;
    float: right;
    //right: 5%;
    margin-right: 0;
margin-top:1%;
margin-left: 25px;
    
}

.VideosInList{
    max-width:168px;
    width: 100%;
    height: auto; //94px
    //position: absolute;
    cursor: pointer;
    border-radius: 10px;
    height: auto;
    //max-height: 150px;
 }





.VideoTitle{
    font-size: 18px;
    display: block;
    position: relative;
    font-weight: bold;
    //top: 100px;
    transition: 1s;
}

.VideoTitle:hover{
    cursor: pointer;
}

.VideoInfo{
//  background-color: blue;
    width: 100%;
    min-height: 40px;
    position: relative;
   // height: 250px;
    transition: 1s;
}

.VideoPublishTime{
    font-size: 14px;
    display: block;
    position: absolute;
    margin-top: 65px;
    color: #737373;
    font-weight: 500;
}

.Panel{
  display: none;

  
}

.Channel{
    width:  48px;
    height: 48px;
    //background: #7a1fa2;
    -moz-border-radius: 50px;
    -webkit-border-radius: 50px;
    border-radius: 50px;
    margin-top: 0px;
    margin-left: 0;
    color: white;
   
    
}

.InfoaboutChannel{
    position: relative;
  
    height: auto;

   
    Button{
       position: relative;
       margin-top: 20px;
       margin-left: 50px;
    }
}

.InfoaboutChannel:hover{
   cursor: pointer;
}

.text-container{
  overflow: hidden;
  text-overflow: ellipsis;
  width: 100%;

}

.p1{
  //  border: 2px solid gold;
  
    position: relative;
    bottom: 0px;
    font-family: 'Roboto', sans-serif;
    font-size: 14px;
    font-weight: 600;
    left:15px;

    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    white-space: nowrap;
 
    width: 67%;

}

.p2{
    color: #737373;
    //border: 2px solid black;
    right: 100px;
    position: relative;
    font-size: 12px;
    font-family: 'Roboto', sans-serif;
    
    
}
.p3{
    
  display: flex;
  position: relative;
  font-size: 14px;
  font-family: "Roboto", sans-serif;

  width: 100%;
  min-height: 55px;
  margin-top: 10px;
  border-radius: 10px;
  background-color: #f2f2f2;

  span{
      font-weight: bold;
      margin-left: 8px;
  }
  
   
}

.Descr{
  padding: 15px 0px 15px 10px;
}

.Comm{
  display: none;
}


.p3:hover{
    
  background-color: #e6e6e6;
  cursor: pointer;
}



.Teilen:hover{
  background-color: #e6e6e6;
  cursor: pointer;
}



.Punkte:hover{
  background-color: #e6e6e6;
  cursor: pointer;
}


.Btn{
  height: 40px;
  //background-color: #000000;
  width: 130px;
  border-radius: 30px;
  text-align: center;
  display: inline-flex;
  align-items: center;
 // justify-content: space-between;
 background-color: #ebebeb;
 margin-right: 20px;
  border-radius: 40px;
//  border: 1px solid black;


}



#btndislike{

display: flex;
align-items: center;
margin-left: 10px;

}

#btnlike{

display: flex;
align-items: center;
margin-left: 10px;
}

#btnlike::after{
  content: "";
  margin-left: 20px;
  height: 25px;
  width: 1px;
  background: black;
}

.Btn:hover{
 background-color: #e6e6e6;
 cursor: pointer;
}



.Teilen{
  height: 40px;
  background-color: #ebebeb;
  width: 116px;
  border-radius: 30px;
  text-align: center;
  display: inline-flex;
  margin-right: 20px;
  align-items: center;
  justify-content: space-around;

  p{
      vertical-align: middle;
      line-height: 8px;
      color: black;
    
     
  }
}

.Punkte{
  height: 40px;
  background-color: #ebebeb;
  width: 40px;
  border-radius: 50%;
  text-align: center;
  display: inline-flex;
 
  align-items: center;
  justify-content: space-around;

  p{
      vertical-align: middle;
      line-height: 8px;
      color: black;
      font-weight: 900;
      padding-bottom: 10px;
     
  }
}



.Save{
 display: none;
  height: 40px;
  width: 100px;
  border-radius: 30px;
  text-align: center;

  align-items: center;
  background-color: #f2f2f2;

  border: 1px solid black;
}



.AboBtn:hover{

  background-color: #525252;
  cursor: pointer;
}


.p4{
    display: none;
}


.VideoContainer{
    width: 100%;
    height: 94px; //94px
   // border: 2px solid green;
    margin-top:10px;
   // padding-bottom: 65px;
    display: flex;
    justify-content: space-evenly;
    
}

.TitleofVideo{
 //   border: 2px solid black;
    height: auto; //auto
    cursor: pointer;
    width: 55%;
    float: right;
   
    #title{
            
            overflow: hidden;
  text-overflow: ellipsis;
display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  line-height: 20px;
            font-family: 'Roboto', sans-serif;
            font-size: 14px;
            font-weight: 500;
            max-width: 205px;
            width: 100%;
            
        }
       .channelTitleUndAufrufe{
            
           
            font-family: 'Roboto', sans-serif;
            font-size: 12px;
            font-weight: 500;
            color: #737373;
            display: block;
            margin-top: 4px;
        }

        

}

.Overlay{
    height: 460px;
    width: 100%; //57%
    background-color: transparent; //transparent
    position: absolute;
    z-index: 0;
    
}

.Overlay2{
position: absolute;
background: transparent;

z-index: 2;
    
}

.video-info_container{
  display: flex;
 flex-direction: column;
  width: 100%;
}

.Video{
    height: 100%;
    width: 100%;
    display: inline-block;
    border-radius: 10px;
   
}

#ad{
    width: 100%;
    //height: 495px;
    background-color: black;
    border-radius: 10px;
}

#ad2{
    width: 100%;
    //height: 495px;
    background-color: black;
    position: absolute;
    top: 0;
    z-index: -1;
    height: 100%;
    
}





.videoTrack{
    height: 5px;
    width: 99%;
   // width: 74%;
    //left:1%;
    background-color: #bdbab9;
    position: relative;

   // bottom: 15.5%;
   top:1%;
   margin: 0 auto;
   
}

.Timeline{
    height: 5px;
    width: 0;
    background-color: #ffcc00;
   // transition: 1s linear;

    //transition: 1s width linear;
   // border-radius: 100px;
}

.BtnSkipp{
 // visibility: visible;
  opacity: 0.9;
  width: 80px;
 
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 45px;
  float: right;
position: relative;
bottom: 200px;
background: #28251c;
cursor: pointer;
border: 2px solid rgb(95, 95, 95);    
color: white;

pointer-events: none;
z-index: 2;
}

//midroll

.midroll-BtnSkipp{
  opacity: 0.9;
  width: 80px;
 
  position: relative;
  display: none;
  align-items: center;
  justify-content: space-between;
  height: 45px;
  float: right;
position: relative;
bottom: 200px;
background: #28251c;
cursor: pointer;
border: 2px solid rgb(95, 95, 95);    
color: white;

pointer-events: none;
z-index: 2;
}


.BtnNonSkipp{
width: 140px;
justify-content: center;
}
.midroll-BtnNonSkipp{
display: none;
width: 140px;
justify-content: center;
}

#skip_ad-textline{
  display: none;
  align-items: center;
}

#skip_ad-textline_non-skip{
  display: none;
  align-items: center;
  font-size: 14px;
}


.BtnSkippEnd{
  visibility: visible;
  opacity: 0.9;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 45px;
  float: right;
position: relative;
bottom: 200px;
background: #28251c;
cursor: pointer;
border: 2px solid rgb(95, 95, 95);    
color: white;
width: 240px;
font-size: 18px;
font-weight: bold;
text-align: center;
pointer-events: all;
z-index: 2;
border-radius: 35px;
}

.BtnSkippEnd:hover {
  
  border: 2px solid white;
  opacity: 1;
}

.Countdown{

height: 100%;
width: 25%;
display: flex;
align-items: center;
justify-content: center;
color: white;
}

.CountdownImg{
  height: inherit;
width: 75%;
}

.loader_container{
position: absolute;

  width: 100%;
  height: 100%;
}


#volumeicon{
background-color: transparent;
font-size: 25px;

}

.Videoplayer:hover #slider{
  opacity: 1;
 width: 12%;
}

.SomeIcons{
  position: absolute;
  right: 0;
}


.Footer{
    position: absolute;
   height: auto;
    width: 100%;
    
    //margin: 20%;
    
   
    opacity: 0;
   // transition: all 2s linear;
  transition-duration: 3s;
  // transition-delay: 15s;
}

.FooterDiv{
  position: relative;
 height: 64px;
  width: 60%;
  top: 80%;
  left: 20%;
  //margin: 20%;
 // margin-top: 25%;
//  margin-left: 20%;
  background-color: transparent; //transparent
  
 
}

.CloseIcon{
    position: absolute;
    opacity: 0;
    height: 20px;
    width: 20px;
    right: 0;
    transition-duration: 3s;
}

.CloseIcon:hover{
  cursor: pointer;
 
}



.Download{
display: none;
}
.Clip{
display: none;
}

.AboDiv{
//display: none;
}



.NewDiv{
max-width: 1280px;
width: 100%;
margin-left: 120px;
display: inline-block;
}


.CircLoad{
position: absolute;
left: 50%;
top: 50%;
display: none;
}

.btnPlay{
position:'relative';
color:'white';
opacity:1;
}


@media  (min-width: 2048px) {
.Main {
  max-width: 1900px;
  width: 100%;
 
  display: flex;
  
}

.Overlay{
  width: 100%;
  height: 565px;
}

.Video {
  //height: 600px;
}
}


@media  (width: 1920px) {



  .Video{
    
  }

  
  .ForwardWindow{
    height:720px;
  }
  

  .Overlay{
    //height: 685px;
 
  }

  #ad{
    //height: 720px;
  }
  #ad2{
    //height: 720px;
  }

  .Videolist{
    right: 0;
    width: 25%;
    margin-right: 25px
  }

}

@media (max-width:1780px) { //1600
  .TitleofVideo{
    //   border: 2px solid black;
       height: auto; //auto
       cursor: pointer;
       width: 55%;
       float: right;
       margin-left: 20px;
  }

  .Main {
    max-width: 1600px;
  }

 .ChannelPanel{
  //flex-direction: column;
 }

 .Videoplayer{
 // max-width: 920px; //880px 1100
  
 }

 .Videolist {
 width: 350px;
  position: relative;
  float: right;
 // margin-right: 65px;
  margin-top: 1.5%;
}
}


@media (max-width:1600px) {

  .Videolist {
  width: 400px;
   }
}

@media (width:1280px) {
  .Videolist {
  //  min-height: 100vh;
    position: relative;
    float: right;
     margin-right: 0px;
    margin-top: 1.5%;
}

.Videoplayer{
 // max-width: 750px;
 // width: 100%;
 }

.BtnSkipp{
bottom: 215px;
}

.BtnSkippEnd{
bottom: 215px;
}

}


@media  (max-width: 1230px) {
  .ButtonsPanel{

      position: relative;
 
    
   //   float: left;
 }

 .Videolist{
  // border: 2px solid red;
  // min-height: 100vh;
   margin-left: 25px;
   //width: 402px;
   width:27%;
    //width: 370px;
 //  display: inline-block;
   position: relative;
   float: right;
   right: 0%;
  // margin-top:5%; wenn 1.5 nicht passt, dann 5
   margin-top:1.5%;
}


}
@media  (max-width: 1200px) {
 

  .Videoplayer{
   // max-width: 725px;
  }

 .Videolist{
 
   margin-left: 25px;
  
   width:27%;

   position: relative;
   float: right;
   right: 0;
  margin-right: 0;
   margin-top:1.5%;
}

}




  @media  (max-width: 1100px) {
    .Main{
      flex-direction: row;
      align-items: start;
      justify-content: space-evenly;
    
    }

    .Videoplayer{
     // width: 95%;
    max-width: 1015px;
    margin-left: 0;
    }

    .Videolist{
      width: 30%;
      right: 0;
      margin-left: 0;
  
    }

    .VideoContainer{
      justify-content: start;
    }

    .TitleofVideo{

      #title{
        max-width: none;
      }
    }

    .p1{
      width: 80%;
    }

    .ButtonsPanel{
      display: none;
    }

    .AboDiv{
      margin-left: 20px;
    }
  }

  /*

@media  (max-width: 950px) {




  .ButtonsPanel{

      //position: relative;
     
      justify-content: start;
    //  float: right;
 }
 

  .TitleofVideo{
   //   border: 2px solid black;
      height: auto;
      margin-top: 10px;
      width: 68%;
      float: right;
     
      #title{
               
        overflow: hidden;
text-overflow: ellipsis;
display: -webkit-box;
-webkit-box-orient: vertical;
-webkit-line-clamp: 2;
line-height: 20px;
        font-family: 'Roboto', sans-serif;
        font-size: 14px;
        font-weight: 500;
        max-width: 455px;
        width: 100%;
      }
  
  }


  .VideosInList{
      width: 30%;
      height:inherit;
     // position: absolute;
   }
   .VideoContainer{
    width: 100%;
    height: 100px;
    cursor: pointer;
      
  }

  .Videoplayer{
      margin-left: 0;
      transition: 1s;
      width: 100%;
      height: auto;
  }


  .Videolist{
   //   border: 2px solid red;
     // min-height: 100vh;
      //width: 402px;
      width:99%;
      margin-left: 0;
      cursor: pointer;
  }

  .Overlay{
      height: 450px;
      width: 100%;
      background-color: transparent;
      position: absolute;
  }
  ::-webkit-scrollbar{width:0;height:0}
  ::-webkit-scrollbar-thumb{background:transparent}
  ::-webkit-scrollbar-track{background-color:transparent}



}

.IosLoadImg{
  position: absolute;
  width: 100%;
  z-index: 1;
  top: 0;
  display: none;
  
}
*/


  @media  (max-width: 480px) {

    .Main{
      flex-direction: column;
      align-items: center;
      justify-content: center;
    
    }

    .OverlayCase{
      position: relative;
     height: 204px;
     
    }

    .ChannelPanel{
      flex-direction: column;
     }

    .Coverlogo{
     
    height: 45px;
   
    }

    .Descr{
      display: none;
    }
  
    .Comm{
      padding: 15px 0px 0px 10px;
      display: flex;
      padding-bottom: 20px;
    }

    #addCommText{
      width: 70%;
      height: 22px;
      background: #e3e3e3;
      border-radius: 30px;
      margin-left: 12px;
      font-size: 11px;
      display: flex;
      align-items: center;
      padding-left: 10px;
      color: gray;
  
    }


    .Container-mobile{
      width: 65%;
      display: flex;
      padding-right: 0px;
    }

     #Comments{
      display: block;
      padding: 15px 0px 0px 10px;
    }

    .p1{
   position: relative;
  font-family: "Roboto", sans-serif;
  font-size: 14px;
  font-weight: 600;
  left: 15px;
  display: flex;
  align-items: center;
  width: 55%;
  bottom: 0;
    }

    .text-container{
      display: inline-block;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
    }

    .Channel-container{
      display: flex;
    }


    .ImgAndAbo{
      width: 100%;
  display: flex;
  padding-bottom: 30px;
  justify-content: space-around;
    }

    .IosLoadImg{
      position: absolute;
      width: 100%;
      z-index: 1;
      top: 0;
      display: none;
      
    }

    .ForwardWindow{
      width: inherit;
      height: 270px;
      position: absolute;
      background-color: #333333;
      z-index: 1;
      border: 4px solid black;
    //  top: 10%;
    //  left:20%;
    //  border-radius: 40px;
      display:none;
      text-align: center;
  }

  

  .ForwardWindowImg{
    width: 30%;
    height: auto;
    margin-top: 30px;
  }
  
  .ForwardWindowText{
    font-size: 4vw ;
  }


    .Btn{
      height: 25px;
      width: 125px;
      margin-right: 10px;
      border: 1px solid black;

      p{
        font-size: 12px;
        padding-left: 10px;
        padding-right: 15px;
      }

      span{
        margin-left: 5px;
      }
    }

    .BtnSkippEnd{
      visibility: visible;
      opacity: 0.9;
      position: relative;

      align-items: center;
      justify-content: center !important;
      height: 25px !important;
      float: right;
  position: relative;
 
  background: #28251c;
  cursor: pointer;
  border: 2px solid rgb(95, 95, 95);    
  color: white;
  width: 135px !important;
  font-size: 10px;
  font-weight: bold;
  text-align: center;
  pointer-events: all;
  bottom: 85px;

  display: flex;
 
    }


    .BtnSkipp{
     
  bottom: 120px;
    }

  
    .Teilen{
      height: 25px;
      width: 105px;
      margin-right: 10px;
      border: 1px solid black;
      p{
        font-size: 12px;
       
        padding-right: 15px;
      }
    }


    .midroll-BtnSkipp{
      opacity: 0.9;
      width: 80px;
     
      position: relative;
      display: none;
      align-items: center;
      justify-content: space-between;
      height: 45px;
      float: right;
    position: relative;
    bottom: 85px;
    background: #28251c;
    cursor: pointer;
    border: 2px solid rgb(95, 95, 95);    
    color: white;
    
    pointer-events: none;
    z-index: 4;
    }


    .midroll-BtnNonSkipp{
      display: flex;
      width: 135px;
      justify-content: center;
      height: 35px;
      }


      #skip_ad-textline_non-skip{
    
        font-size: 13px;
      }
      


    .Save{
        
       
      height: 25px;
      width: 105px;
      border-radius: 30px;
      text-align: center;
      display: inline-flex;
      align-items: center;
      background-color: #ebebeb;
      margin-right: 10px;
      border: 1px solid black;

      p{
        font-size: 12px;
       
      
      }
  }


  .Clip{

      
    height: 25px;
    width: 55px;
      border-radius: 30px;
      text-align: center;
      display: inline-flex;
      align-items: center;
      background-color: #ebebeb;
      margin-right: 10px;
      border: 1px solid black;

      p{
        font-size: 12px;
       
        padding-right: 15px;
      }
  }



  .Download{
     
    height: 25px;
    width: 125px;
border-radius: 30px;
text-align: center;
display: inline-flex;
align-items: center;
margin-right: 10px;
background-color: #ebebeb;

border: 1px solid black;


p{
font-size: 12px;


}

}

    .NewDiv{
    
    margin-left: 0px;
    margin-top: -20px;
    overflow-x: hidden;
    
    }

    #volumebtn{
      display: none;
    }
    #playbtn{
      display: none;
    }
    #pausebtn{
      display: none;
    }



    #volumeicon{
      display: none;
    }
    #volumeslider{
      display: none;
    }

    .Unmute{
     
      top:10%;
      bottom: inherit;
     width: 22%;
     left: 35%;
  
  }

    


      .Adshell{
          position: relative;
          height: 204px; //auto
         
        }

      .AboDiv{
          
        position: relative;
        width: 30%;
        height: 40px;
        display: flex;
         padding-left: 0;
        justify-content: center;
         
      }

      .AboBtn{
        transform: scale(0.7);
        height: 40px;
        background-color: #000000;
        width: 116px;
        border-radius: 30px;
        text-align: center;
        top: 0px;
        color: white;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 16px;
        font-weight: bold;
          
      
        }




      .Punkte{
          display: none;
      }


     

      #btndislike{

        display: flex;
        align-items: center;
        margin-left: 10px;
      
        }
      
       #btnlike{
       
        display: flex;
        align-items: center;
        margin-left: 5px;

        
       }

       #btnlike::after{
        content: "";
        margin-left: 0px;
        height: 25px;
        width: 1px;
        background: black;
     }
    

      .ButtonsPanel{
          //border: 2px solid red;
         overflow-y: hidden;
          width: 100%;
          display: block;
          overflow-x: auto;
          white-space: nowrap;
        }

      .Channel{
        width: 38px;
        height: 38px;
        border-radius: 50px;
         margin-top: 0px;
        margin-left: 0;
        color: white;
          
      }



      .Panel{
          //  background-color: lawngreen;
            height: 50px;
            width: inherit;
            left: 0;
            position: absolute;
            bottom: 30%;
            -webkit-overflow-scrolling: touch;
            overflow: auto;
            white-space: nowrap;
            display: block;
            
        }

      #slider{
          width: 40%;
      }
  

      .p3{
    
          display: flex;
          //position: absolute;
          font-size: 14px;
          font-family: 'Roboto', sans-serif;
          /* left: 60px; */
          
          height: auto;
         // margin-top: 10px;
          border-radius: 10px;
          background-color: #f2f2f2;
          //justify-content: center;
          display: -webkit-box;
          overflow: hidden;
          -webkit-line-clamp: 3;
          -webkit-box-orient: vertical;
          bottom: 0;
          width: 100%;
          
          span{
              font-weight: bold;
          }
      }
     


      #volumebtn{
         //width: 40%;
         width: 90%;
        //  bottom: 35%;
          left: 2%;
      }

      .AdPanel{
        position: absolute;
        width: 100%;
        height: 5px;
        bottom: 0;
        background-color: transparent;
        opacity: 1 !important;
        display: inline-block;
        cursor: pointer;
      }

    .VideoPublishTime{
        display: none;
    }

    .VideosInList{
        width: 100%;
        max-width: 100%;
        object-fit: cover;
        border-radius: 0px;
        height: auto;
        max-height: 200px;
        padding-top: 0;
     }
     .VideoContainer{
        width: 100%;
        height: auto;
        display: block;
     //   border: 2px solid green;
        
    }

    .Videolist{
    //    min-height: auto;
        width: 100%;
        margin-left: 0;
        cursor: pointer;
        right: 0;
        margin-right: 0;
    }

    .TitleofVideo{
        height: auto;
        margin-top: 10px;
        width: 95%;
        padding-bottom: 10%;
    }

 .PlayBtn{
     border-radius: 5px;
     width: 20px;
     background-color: white;
 }

    .InfoaboutChannel{
    
      //  border: 2px solid red;
        height: auto;
        position: relative;
       // border: 2px solid black;
        margin-top: -50px;
      //  padding-bottom: 140px;
        left: 5px;
    
        p1{
          //  border: 2px solid gold;
            display: inline-block;
            position: relative;
            bottom: 20px;
            font-family: 'Roboto', sans-serif;
            font-size: 14px;
            font-weight: 600;
            left:15px;
        }
        p2{
            color: #737373;
            //border: 2px solid black;
            right: 100px;
            position: relative;
            font-size: 12px;
            font-family: 'Roboto', sans-serif;
            
            
        }
        p3{
            
          display: none;
           
        }
        p4{
            display: inline;
            color: red;
            margin-top: 30px;
            float: right;
            font-weight: 600;
        }
        Button{
           display: none;
        }
    }


    .Overlay{
        height: 148px; //217
        width: 100%;
        background-color: transparent;
        position: absolute;
        z-index: 3;
    }



    .Video{
       height:100%; //270
        width: 100%;
        display: inline-block;
        
       
    }
    
    #ad{
        width: 100%;
        //height: auto; //270
        height: 100%;
    }
    #ad2{
        width: 100%;
        //height: auto; //270
        height: 100%;
    }

    .Videoplayer{
        margin-left: 0;
        transition: 1s;
        width: 100%;
        height: auto;
        margin-top: 0;
    }

    .Cringe{
        height: 260px;
        width: 100%;
        background-color: transparent;
    }

    .VideoTitle{
        font-size: 16px;
        display: block;
        position: absolute;
        font-weight: bold;
        //top: 100px;
        transition: 1s;
        display: -webkit-box;
        overflow: hidden;
        -webkit-line-clamp: 3;
        -webkit-box-orient: vertical;
    }
    
    .VideoInfo{
    //  background-color: blue;
        width: 100%;
        //min-height: 70px;
        min-height: 120px;
        position: relative;
       // height: 130px;
        transition: 1s;
        left: 5px;
        margin-top: 5px;

    }

    .Footer{
     display: none;
     
  }


  .videoTrack{
      height: 3px;
     // width: 100%;
      background-color: transparent;
      position: relative;
      top: 15%;
      width: 100%;
  }

  .Timeline{
      height: 3px;
      width: 0;
      background-color: #e9ba28;
      transition: 1s linear;
  }





.Back{
  cursor: pointer;
  pointer-events: all;
  font-size: .900rem;
  font-weight: bold;
  border: 2px solid;
  width: 45%;
  height: 30px;
  margin: 0 auto;
  background: white;
  color: black;
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 20px;
}

    
  }



   