


body{
	//background-color: #f9f9f9;
}

.VideoAdHidden{
	position: absolute;
	width: 10px;
	height: 10px;
	z-index: -1;
}

.ErrorPage{
	width: 100%;
	height: 100vh;
}



.ErrorBody{
	//background: #69b1db;
	background: rgb(97, 97, 97);
	
	width: 100%;
	height: 100vh;
   
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
}

.ErrImgandText{
	width: 30%;
	margin-bottom: 5%;
	cursor: pointer;
}

.ErrImgDiv{
	width: 100%;
	//margin-bottom: 20%;
	

	img{
		width: 100%
	}
}

.ErrTextDiv{
	
	text-align: center;
	font-size: 30px;
	color: white;
	font-weight: bold;
	margin-top: 10%;
}

@import url('https://fonts.googleapis.com/css2?family=Trirong&display=swap');


@media  (max-width: 480px) {

	.ErrImgandText{
		width: 80%;
		margin-bottom: 30%;
	}

	.ErrImgDiv{
		width: 100%;
		

	
		img{
			width: 100%
		}
	}


	.ErrTextDiv{
	
		text-align: center;
		font-size: 18px;
		
		color: white;
		font-weight: bold;
		margin-top: 10%;
		
	}
	
}
