.AdminPanel{
    width: 100%;
    height: 100vh;
    display: flex;
    align-items: center;

}

.BlockVideos{
    width: 60%;
    height: 100vh;
    border: 2px solid black;
    overflow-y: auto
}

.ControlPanel{
    width: 40%;
    height: 50vh;
    border: 2px solid green;
    display: flex;
    flex-direction: column;
}

.Controls{
    border: 2px solid red;
    width: 100%;
    height: 50%;

    display: flex;
    justify-content: space-evenly;
    align-items: center;
}

#themafield{
    width: 100%;
    height: 15%;
    font-size: 1.2rem;
    max-width: 210px;
}

#countfield{
    width: 100%;
    height: 15%;
    font-size: 1.2rem;
    max-width: 70px;
}

#gobtn{
    width: 100%;
    height: 35px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #1976d2;
    color: white;
    box-shadow: 0px 10px 10px -6px #595959;
    border-radius: 5px;
    margin: 40px auto;
    max-width: 95px;
    cursor: pointer;
}