.Body{
    
    width: 100%;
 
    background-color: white;

    display: block;
    position: sticky;
    top:0;
    z-index: 2;
}

.HamburgerMenu{
    display: flex;
    align-items: center;
    
  }

.Drawer{
    display: flex;
    flex-direction: row;
    height: 60px;
    
}

.MenuDiv{
  display: flex;
    flex-direction: row-reverse;
}

.Menu{
  width: 255px;
  overflow-x: hidden;
  background-color: white;
  min-height: 300px;
  max-height: 100vh;
  height: 100%;
  position: sticky;
  cursor: pointer;
  top: 61px;
  overflow-y: hidden;
 

  
  .IconandText{

    width: 85%;
    height: 40px;
    display: flex;
    align-items: center;
    border-radius: 10px;
    margin-bottom: 14px;
    font-size: 14px;
    font-weight: 600;
    margin-top: 15px;

 .IconMenu{
  margin-left: 15px;
 }

 .IconDescr{

  margin-left: 20px;
 }
}

}


.Menu:hover{
overflow-y: scroll;
}




.MenuIcon{
  margin-bottom: 30px;
}

.Instruments{
  
  width: 100%;
display: flex;
justify-content: space-between;
}


.Icons{
width: 10%;
display: flex;
align-items: center;
justify-content: space-evenly;
}

.Logo{
    width: 100px;
    display: flex;
    align-items: center;
   
    
}

.YLogo{
  width: 100%;
}

.Logo:hover{
  cursor: pointer;
}


.LogoYoutcheck{
    width: 115px;
   
   margin-top: 15px;
   height: 30px;
  
}


.SearchField{
  width: 80%;
height: 36px;

border-radius: 40px 0 0 40px;
border: 1px solid #cccccc;
box-shadow: inset 0 1px 2px #eeeeee;
  //14↑
     
}

.SearchField::-webkit-input-placeholder{
 padding-left: 10px;
}

.SearchFielddiv{
  max-width: 662px;
  width: 100%;
  position: relative;
  height: 100%;

display: flex;
align-items: center;
    
}

.Icondiv{
   width: auto;
}

.SearchBtn{
    height: 31px;
   top:15px;
   left:196px;
   width: 20%;
}

.Circle{
    width:  35px;
	height: 35px;
	//background: #7a1fa2;
    background: red;
	-moz-border-radius: 50px;
	-webkit-border-radius: 50px;
	border-radius: 50px;
    margin-top: 12px;
    margin-left: 20px;
    color: white;
    display: flex;
    justify-content: center;

    p{
        font-size: large;
        margin-top: 6px;
    }
}


.Circle:hover{
   cursor: pointer;
}


.HeadSearchBtn{
  height: 96%;
  border:1px solid #cccccc;
  border-radius: 0px 40px 40px 0px;
  width: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.MenuLine{
  width: 100%;
  height: 1px;
  background-color: gray;
  margin: 2px 0 10px 0;
}

.MenuHead{
  width: 100%;
  height: 40px;
  display: flex;
  align-items: center;
  margin-bottom: 14px;
  margin-left: 15px;
  font-weight: 600;
  font-size: 15px;
}

@media  (max-width: 950px) {
    
  .Menu{
    display: none;
    
  }

  }


@media  (max-width: 576px) {


  #Icons-videocall{
    display: none;
  }

  #Icons-account{
    display: none;
  }


  .Icons{

    height: inherit;
    display: flex;
    justify-content: space-around;
 }

    

  .HeadSearchBtn{
    border: none;
    position: absolute;
    right: 5px;
    top: 0;
    width: 40px;
  }
  .SearchFielddiv{
    
  }


  .SearchField{
    display: none;
    height: 24px;
    margin-top: 17px;
    transition: 1s;
    border-radius: 40px 0 0 40px;
  }


  .HamburgerMenu{
    display: none;
  }

  .MenuDiv{
    display: none;
  }

  .Body{
    
    
   // display: none;
   
}



.Logo{

  width: 50%;
  margin-left: 5px;
  
}


.LogoYoutcheck{
  width: 100px;
  margin-left: 8px;
 margin-top: 15px;
 height: 30px;

}

.YLogo{
  width: 100%;
}


.Menu{
  display: none;
  
}

  }